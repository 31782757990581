import { z } from 'zod';

import { formatDate } from '@/helpers/format-date';

const BrandSchema = z.object({
  id: z.string().uuid(),
  name: z.string(),
  contactUrl: z.string().url(),
  policyUrl: z.string().url(),
  reportUrl: z.string().url(),
  authAppBasePath: z.string().url(),
  companyId: z.string().uuid(),
  createdAt: z.string().transform(formatDate),
  updatedAt: z.string().transform(formatDate),
  logo: z.string().nullable(),
});

const CatalogItemSchema = z.object({
  id: z.string().uuid(),
  name: z.string(),
  type: z.string(),
  description: z.string(),
  manufacturer: z.string(),
  specs: z.string(),
  isActive: z.boolean(),
  createdAt: z.string().transform(formatDate),
  updatedAt: z.string().transform(formatDate),
  alias: z.string(),
  encodingPlaceholder: z.string().nullable(),
});

export const OrderSchema = z.object({
  id: z.string().uuid(),
  status: z.enum([
    'Pending',
    'Processing',
    'Ready',
    'Shipped',
    'Delivered',
    'Completed',
    'Cancelled',
    'On Hold',
    'Failed',
    'Returned',
    'Refunded',
  ]),
  code: z.string(),
  brandId: z.string().uuid(),
  providerId: z.string().uuid().nullable(),
  brand: BrandSchema,
  requiredAt: z.string().nullable(),
  catalogItem: CatalogItemSchema,
  amount: z.number(),
  createdAt: z.string().transform(formatDate),
  updatedAt: z.string().transform(formatDate),
  tidCount: z.number(),
  availableTidCount: z.number(),
});

export type Order = z.infer<typeof OrderSchema>;
