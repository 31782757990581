import { Badge } from '@/components/ui/badge';
import { Checkbox } from '@/components/ui/checkbox';
import type { Task } from '@/data/schema';
import { Authorization } from '@/lib/authorization';
import type { Order } from '@/schemas/order';

import { labels, statuses } from '../../data/data';

import { DataTableColumnHeader } from './data-table-column-header';
import { DataTableRowActions } from './data-table-row-actions';

import type { ColumnDef } from '@tanstack/react-table';

export const columns: ColumnDef<Order>[] = [
  {
    accessorKey: 'code',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Code" />
    ),
    cell: ({ row }) => {
      const label = labels.find((label) => label.value === row.original.code);

      return (
        <div className="flex space-x-2">
          {label ? <Badge variant="outline">{label.label}</Badge> : null}
          <span className="max-w-[500px] truncate font-medium">
            {row.getValue('code')}
          </span>
        </div>
      );
    },
  },
  {
    accessorKey: 'chipModel',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Chip Model" />
    ),
    cell: ({ row }) => {
      return (
        <div className="flex space-x-2">{row.original.catalogItem.name}</div>
      );
    },
  },
  {
    accessorKey: 'status',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Status" />
    ),
    cell: ({ row }) => {
      const status = statuses.find(
        (status) => status.value === row.getValue('status'),
      );

      if (!status) {
        return null;
      }

      return (
        <div className="flex w-[100px] items-center">
          <span>{status.label}</span>
        </div>
      );
    },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
  },
  {
    accessorKey: 'requestedTags',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Requested Tags" />
    ),
    cell: ({ row }) => {
      return (
        <div className="flex space-x-2">
          <span className="max-w-[500px] truncate font-medium">
            {row.original.amount}
          </span>
        </div>
      );
    },
  },
  {
    accessorKey: 'tidCount',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Delivered Tags" />
    ),
    cell: ({ row }) => {
      return (
        <div className="flex space-x-2">
          <span className="max-w-[500px] truncate font-medium">
            {row.original.tidCount}
          </span>
        </div>
      );
    },
  },
  {
    accessorKey: 'availableTidCount',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Available Tags" />
    ),
    cell: ({ row }) => {
      return (
        <div className="flex space-x-2">
          <span className="max-w-[500px] truncate font-medium">
            {row.getValue('availableTidCount')}
          </span>
        </div>
      );
    },
  },
  {
    accessorKey: 'createdAt',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Ordered at" />
    ),
    cell: ({ row }) => {
      return (
        <div className="flex space-x-2">
          <span className="max-w-[500px] truncate font-medium">
            {row.getValue('createdAt')}
          </span>
        </div>
      );
    },
  },
  {
    accessorKey: 'updatedAt',
    header: ({ column }) => (
      <DataTableColumnHeader column={column} title="Updated at" />
    ),
    cell: ({ row }) => {
      return (
        <div className="flex space-x-2">
          <span className="max-w-[500px] truncate font-medium">
            {row.getValue('updatedAt')}
          </span>
        </div>
      );
    },
  },
  {
    id: 'actions',
    cell: ({ row }) => (
      <Authorization allowedRoles={['Admin']}>
        <DataTableRowActions row={row} />
      </Authorization>
    ),
  },
];
