import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { ShortenedUUID } from '@/components/shortened-uuid';
import { Badge } from '@/components/ui/badge';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { getMetafields } from '@/helpers/get-metafields';
import { useScanQuery } from '@/hooks/use-scan-query';
import { useSearchFilters } from '@/hooks/use-search-filters';
import { useTidQuery } from '@/hooks/use-tid-query';
import { scanColumns } from '@/modules/scans/components/data-table/scan-columns';
import { DataTable } from '@/modules/scans/components/data-table/scan-table';

import type {
  ColumnFiltersState,
  PaginationState,
} from '@tanstack/react-table';

export function Tid() {
  const { tidId } = useParams() as { tidId: string };
  const filters = useSearchFilters();
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  const tidQuery = useTidQuery(tidId);

  const scanQuery = useScanQuery({
    filters: columnFilters,
    tidId,
    pagination: {
      take: pagination.pageSize,
      skip: pagination.pageIndex * pagination.pageSize,
    },
  });

  useEffect(() => {
    setColumnFilters(filters);
  }, [filters]);

  const metafields = getMetafields(tidQuery.data?.tid.batch.batchMetafields);

  return (
    <>
      <Card>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Created At</TableHead>
              <TableHead>TID</TableHead>
              <TableHead>Product Name</TableHead>
              <TableHead>SKU</TableHead>
              <TableHead>Product Status</TableHead>
              <TableHead className="text-right">Scans</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            <TableRow>
              <TableCell className="font-medium">
                {tidQuery.data?.tid.createdAt}
              </TableCell>
              <TableCell>{tidQuery.data?.tid.id}</TableCell>
              <TableCell>{tidQuery.data?.tid.batch.product.name}</TableCell>
              <TableCell>{tidQuery.data?.tid.batch.product.sku}</TableCell>
              <TableCell>{tidQuery.data?.tid.isActive}</TableCell>
              <TableCell className="text-right">
                {scanQuery.data?.pagination?.total}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Card>
      <div className="grid gap-4 lg:grid-cols-6">
        <Card className="flex flex-col gap-4">
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-base font-medium w-full flex gap-4 justify-between items-center">
              <span className="text-muted-foreground">Batch Id:</span>
              <ShortenedUUID
                copyHidden
                uuid={tidQuery.data?.tid.batchId ?? ''}
              />
            </CardTitle>
          </CardHeader>
          <CardContent className="w-full flex flex-col gap-4">
            <p className="w-full flex gap-4 justify-between">
              <span className="text-muted-foreground">Lot:</span>
              {tidQuery.data?.tid.batch.lot ?? ''}
            </p>
            {tidQuery.data?.tid.batch.producedAt ? (
              <p className="w-full flex gap-4 justify-between">
                <span className="text-muted-foreground">Produced at:</span>
                {tidQuery.data.tid.batch.producedAt}
              </p>
            ) : null}
            {tidQuery.data?.tid.batch.expiresAt ? (
              <p className="w-full flex gap-4 justify-between">
                <span className="text-muted-foreground">Expires at:</span>
                {tidQuery.data.tid.batch.expiresAt}
              </p>
            ) : null}
            {metafields.map((field) => (
              <p className="w-full flex gap-4 justify-between" key={field.key}>
                <span className="text-muted-foreground">{field.key}:</span>
                {field.value}
              </p>
            ))}
            {tidQuery.data?.tid.batch.tags?.length ? (
              <div className="flex flex-col gap-2">
                <p className="w-full flex gap-4 justify-between">
                  <span className="text-muted-foreground">Tags:</span>
                </p>
                <div className="flex flex-wrap gap-1">
                  {tidQuery.data.tid.batch.tags.map((tag) => (
                    <Badge key={tag} variant="secondary">
                      {tag}
                    </Badge>
                  ))}
                </div>
              </div>
            ) : null}
          </CardContent>
        </Card>
        <div className="col-span-5">
          <DataTable
            columnFilters={columnFilters}
            columns={scanColumns}
            data={scanQuery.data?.scans ?? []}
            initialColumnVisibility={{
              tidId: false,
              productName: false,
              view: false,
            }}
            onColumnFiltersChange={setColumnFilters}
            onPaginationChange={setPagination}
            pagination={pagination}
            rowCount={scanQuery.data?.pagination?.total ?? 0}
            showToolbar={false}
          />
        </div>
      </div>
    </>
  );
}
